import React from "react";
import "./GridPerspectiveFloor.scss";
function GridPerspectiveFloor() {
  return (
    <div className="starGridWrapper">
      <div className="starGrid">
        <div className="starGridVerticalFlex">
          {[...Array(11)].map(() => (
            <div className="starGridLine"></div>
          ))}
        </div>
        <div className="dots">
          {[...Array(9)].map(() => (
            <div className="starGridDot"></div>
          ))}
        </div>
        <div className="starGridHorizontalFlex">
          {[...Array(11)].map(() => (
            <div className="starGridLine"></div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GridPerspectiveFloor;
