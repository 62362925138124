import React, { FC } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { AppAnalytics } from "src/helpers";
import {
  InfoIcon,
  PencilDrawIcon,
  ServerIcon,
  VerticalMenuIcon,
} from "@100mslive/react-icons";
import { Box, Flex, Text, Tooltip } from "@100mslive/react-ui";
import OptionItem from "../Common/OptionItem";
import Options from "../Common/Options";
import "./starterKit.scss";

interface Template {
  id: string;
  name: string;
  subdomain?: string;
  subdomain_name?: string;
  createdAt: string | number;
  room_id: string;
  room_enabled: boolean;
}

type TemplateProps = {
  isDefaultTemplate: boolean;
  toggleDelete?: () => unknown;
  template: Template;
  setTemplateToBeEdited: () => void;
};

const iconStyle = {
  height: "20px",
  width: "20px",
  color: "inherit",
};

const TemplateCard: FC<TemplateProps> = ({
  isDefaultTemplate,
  template,
  setTemplateToBeEdited,
}) => {
  const subdomain = template?.subdomain || template?.subdomain_name;
  const days = moment().diff(`${new Date(template?.createdAt)}`, "days");

  return (
    <Flex
      direction="column"
      css={{
        bg: "$surfaceDefault",
        border: "$space$px solid $borderDefault",
        r: "$0",
        p: "$10 $8",
        w: "100%",
      }}
    >
      <Flex justify="between" align="center">
        <Text className="truncated" title={template?.name}>
          {template?.name}
        </Text>

        <Flex>
          {isDefaultTemplate ? (
            <Tooltip
              title={
                <Flex css={{ w: "$56" }} justify="start" align="start">
                  <Box css={{ h: "$8" }}>
                    <InfoIcon width={16} height={16} />
                  </Box>
                  <Text variant="caption" css={{ mt: "$1", ml: "$2" }}>
                    The default template acts as a fallback if you create a room
                    without a template ID (via Server Side APIs).
                  </Text>
                </Flex>
              }
              side="bottom"
              align="center"
            >
              <Text
                variant="overline"
                css={{ bg: "$surfaceLighter", p: "$2 $4", r: "$0" }}
              >
                DEFAULT
              </Text>
            </Tooltip>
          ) : null}

          <Options
            Icon={<VerticalMenuIcon width={24} height={24} />}
            content={[
              {
                data: (
                  <OptionItem
                    className="icon-color"
                    onClick={() =>
                      AppAnalytics.track("btn.clicked", {
                        btnId: "edit.template.name.templates",
                        componentId: "dashboardcard",
                      })
                    }
                    title="Edit Template Name"
                    icon={<PencilDrawIcon style={iconStyle} />}
                  />
                ),
                func: () => setTemplateToBeEdited(),
              },
            ]}
          />
        </Flex>
      </Flex>
      <Flex justify="start" align="center" css={{ c: "$textMedEmp" }}>
        {subdomain ? (
          <>
            <Flex
              css={{ c: "$textDisabled", h: "100%", mr: "$2" }}
              align="center"
            >
              <ServerIcon width={16} height={16} />
            </Flex>
            <Text
              title={subdomain}
              variant="caption"
              css={{
                c: "$textMedEmp",
                maxWidth: "90%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {subdomain}
            </Text>
          </>
        ) : (
          <Text css={{ c: "$textMedEmp" }} variant="caption">
            No Subdomain
          </Text>
        )}
      </Flex>
      <Flex justify="between" align="center" css={{ mt: "$11" }}>
        <Text variant="caption" css={{ c: "$textDisabled" }}>
          Created {days ? days : "<1"}d ago
        </Text>
        <Link to={`/templates/${template.id}`}>
          <Text variant="sub2" css={{ c: "$primaryLight", pr: "$5" }}>
            Configure
          </Text>
        </Link>
      </Flex>
    </Flex>
  );
};

export default TemplateCard;
