import { useState } from "react";
import { CheckCircleIcon, CopyIcon } from "@100mslive/react-icons";

export default function Copy({
  text,
  className,
  onClick = undefined,
  iconClassName,
}) {
  const [copied, setCopied] = useState(false);
  return (
    <button
      onClick={() => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
        if (onClick) {
          onClick();
        }
      }}
      className={className}
    >
      {!copied ? (
        <CopyIcon className={iconClassName} />
      ) : (
        <CheckCircleIcon className={iconClassName} />
      )}
    </button>
  );
}
