import api from "../api";

export const getStrapiReferrerSource = async () => {
  try {
    const { data } = await api.service("cms").get(
      "api/referrer-source",
      {
        populate: "*",
      },
      {
        headers: null,
      }
    );
    return data.data.attributes.list.map(value => value.text);
  } catch {
    return [];
  }
};
