import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { fetchTemplatesData } from "src/actions/RolesActions";
import { API_CALL_STATE, validationMessagesKeys } from "src/constants";
import { AppAnalytics } from "src/helpers";
import {
  cancelCheckTemplateName,
  checkTemplateName,
} from "src/store/createTemplate/actions";
import { RootState } from "src/store/reducers";
import Messages from "src/validations/Messages";

/**
 * * @description custom hook to check the template name
 * @params setTemplateName - function to set the template name
 * @description error - error message for template name
 * @description status - status of the API call
 * @description changeHandler - function to handle the change in template name
 * @returns {object} - {error, status, changeHandler}
 
 */

type templateName = {
  error: string;
  status: string;
  changeHandler: (name: string) => void;
};

export const useCheckTemplateName = ({
  setTemplateName,
  name,
}: {
  setTemplateName: (name: string) => void;
  name: string;
}): templateName => {
  const checkTemplateNameStatus = useSelector(
    (state: RootState) => state.createTemplate.checkTemplateNameStatus
  );
  const all_templates = useSelector(
    (state: RootState) => state.roles.all_templates
  );
  const fetchTemplateStatus = useSelector(
    (state: RootState) => state.roles.fetchTemplateStatus
  );
  const checkTemplateNameError = useSelector(
    (state: RootState) => state.createTemplate.search.template.error
  );
  const dispatch = useDispatch();
  const [templateNameError, setTemplateNameError] = useState<string>("");
  const getRegexError = (name: string) =>
    Messages(
      validationMessagesKeys.validTemplateName,
      name,
      true,
      all_templates.map(r => r.name) || []
    );
  useEffect(() => {
    if (all_templates?.length === 0 && fetchTemplateStatus === "") {
      dispatch(fetchTemplatesData());
    }
  }, [all_templates?.length, dispatch, fetchTemplateStatus]);

  useEffect(() => {
    const error = checkTemplateNameError || getRegexError(name);
    if (
      checkTemplateNameStatus === API_CALL_STATE.FAILED ||
      getRegexError(name)
    ) {
      setTemplateNameError(error);
    } else {
      setTemplateNameError("");
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [checkTemplateNameStatus]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCheckTemplateName = useCallback(
    debounce((name: string) => {
      dispatch(checkTemplateName(name, false)); // Fetch new data
    }, 500),
    []
  );

  const debounceSaveTemplateName = (name: string) => {
    dispatch(cancelCheckTemplateName()); // Cancel the previous request if still ongoing
    debounceCheckTemplateName(name);
  };

  const handleTemplateNameChange = (
    name: string,
    btnId = "template.rename.success",
    componentId = "configure.your.template.step"
  ) => {
    const error = getRegexError(name);

    dispatch(cancelCheckTemplateName());
    setTemplateName(name);

    //no need to update debounce save to store as error exists in regex
    if (!error) {
      debounceSaveTemplateName(name);
      AppAnalytics.track("btn.clicked", {
        btnId: btnId,
        componentId: componentId,
      });
    }
    setTemplateNameError(error);
  };
  return {
    error: templateNameError,
    status: checkTemplateNameStatus,
    changeHandler: handleTemplateNameChange,
  };
};
