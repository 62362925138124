import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import {
  cancelCheckSubdomainName,
  checkSubdomain,
} from "src/actions/RolesActions";
import { API_CALL_STATE, validationMessagesKeys } from "src/constants";
import { AppAnalytics } from "src/helpers";
import { RootState } from "src/store/reducers";
import Messages from "src/validations/Messages";

/**
 *  @description custom hook to check the subdomain name
 * @params setSubdomain - function to set the subdomain name
 * @description error - error message for subdomain name
 * @description status - status of the API call
 * @description changeHandler - function to handle the change in subdomain name
 * @returns {object} - {error, status, changeHandler}
 *
 * */

type subdomainReturn = {
  error: string;
  status: string;
  changeHandler: (name: string) => void;
};

export const useCheckSubdomain = ({
  setSubdomain,
  subdomain,
}: {
  setSubdomain: (name: string) => void;
  subdomain: string;
}): subdomainReturn => {
  const checkSubdomainStatus = useSelector(
    (state: RootState) => state.roles.checkSubdomainStatus
  );
  const checkSubdomainError = useSelector(
    (state: RootState) => state.roles.checkSubdomain.error
  );
  const dispatch = useDispatch();
  const [subdomainNameError, setSubdomainNameError] = useState<string>("");
  const getRegexError = (name: string) =>
    Messages(validationMessagesKeys.validSubdomain, name, true, []);
  useEffect(() => {
    const error = checkSubdomainError || getRegexError(subdomain);
    if (
      checkSubdomainStatus === API_CALL_STATE.FAILED ||
      getRegexError(subdomain)
    ) {
      setSubdomainNameError(error);
    } else {
      setSubdomainNameError("");
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [checkSubdomainStatus]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCheckSubdomain = useCallback(
    debounce((name: string) => {
      dispatch(checkSubdomain(name)); // Fetch new data
    }, 500),
    []
  );

  const debounceSaveSubdomain = (name: string) => {
    dispatch(cancelCheckSubdomainName()); // Cancel the previous request if still ongoing
    debounceCheckSubdomain(name);
  };

  const handleSubdomainNameChange = (
    name: string,
    btnId = "subdomain.rename.success",
    componentId = "configure.your.template.step"
  ) => {
    const error = getRegexError(name);

    setSubdomain(name);
    dispatch(cancelCheckSubdomainName());
    //no need to update debounce save to store as error exists in regex
    if (!error) {
      debounceSaveSubdomain(name);
      AppAnalytics.track("btn.clicked", {
        btnId: btnId,
        componentId: componentId,
      });
    }
    setSubdomainNameError(error);
  };
  return {
    error: subdomainNameError,
    status: checkSubdomainStatus,
    changeHandler: handleSubdomainNameChange,
  };
};
